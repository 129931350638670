import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="allergien" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Allergien / Unverträglichkeiten`}</h1>
      <h3 {...{
        "id": "allergien--unvertraglichkeiten"
      }}>{`Allergien / Unverträglichkeiten`}</h3>
      <p>{`Allergische Erkrankungen sind überschießende Reaktionen eines irritierten, geschwächten Immunsystems. Allergien und Unverträglichkeiten kommen meist im Verbund vor – insbesondere im Lebensmittel-Bereich. Sie haben unterschiedliche immunologische Mechanismen – sind aber vom Betroffenen an den Beschwerdebildern kaum zu unterscheiden. Unverträglichkeiten sind im Unterschied zu den echten Allergien mengenabhängig.`}</p>
      <p>{`D.h., eine geringe Menge eines beispielsweise unverträglichen Lebensmittels macht noch keine Beschwerden – erst die größere Menge bzw. die Kombination mit anderen Unverträglichkeiten macht Ärger und sorgt bei den Betroffenen für Verwirrung und Unsicherheit. Daher macht ein sog. „Ernährungstagebuch“ nicht all zu viel Sinn – zumal noch weitere sog. allergische Spätreaktionen eine Rolle spielen können: Lebensmittel, am Montag gegessen, führen evtl. erst am Mittwoch zu Beschwerden und sorgen für Chaos im Ernährungstagebuch…`}</p>
      <p>{`Ein Allergen (Allergieauslöser) kann prinzipiell überall im Körper Ärger machen: so führen Atemwegsallergene nicht immer nur zu Beschwerden in den Bronchien, sondern können auch Kopfschmerzen auslösen. Weizenmehl und Kuhmilch führen nicht zwangsweise zu Darmbeschwerden chronischen Darmerkrankungen), sondern können auch für Asthmaanfälle und Hauterkrankungen verantwortlich sein!`}</p>
      <hr></hr>
      <p>{`Um bestehende Allergien langfristig zu behandeln, ist es erforderlich, die Auslöser mit der `}<a parentName="p" {...{
          "href": "/diagnoseverfahren/bioresonanz-testung"
        }}>{`Bioresonanz-Testung`}</a>{` zu definieren und mit der `}<a parentName="p" {...{
          "href": "/therapien/bioresonanz-therapie"
        }}>{`Bioresonanz-Therapie`}</a>{` zu neutralisieren. In den meisten Fällen ist eine begleitende Immunstärkung mit homöopathischen u/o pflanzlichen Mitteln sinnvoll.`}</p>
      <p>{`Insbesondere chronische Atemwegserkrankungen, Infektanfälligkeit, Nasen-Nebenhöhlen-Probleme und `}<a parentName="p" {...{
          "href": "/beschwerdebilder/chronische-hauterkrankung"
        }}>{`chronische Hauterkrankungen`}</a>{` wie Neurodermitis erfordern ein umfassendes ganzheitstherapeutisches Konzept: vom `}<a parentName="p" {...{
          "href": "/diagnoseverfahren/stuhl-labor"
        }}>{`Stuhl-Labor`}</a>{` über gezielte `}<a parentName="p" {...{
          "href": "/therapien/darmsanierung"
        }}>{`Darmsanierung`}</a>{` bis hin zur langfristigen `}<a parentName="p" {...{
          "href": "/beschwerdebilder/immunstaerkung"
        }}>{`Immunstärkung`}</a>{`.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      